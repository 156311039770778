import React from 'react';
import Logo from './Logo';
import Menu from './Menu';
import '../css/main.css';
import '../css/animations.css';
import '../css/fonts/proximaNova.css';
import SEO from './seo';
import Footer from './Footer';

export default function Layout(props){
    return(
    <>
    <SEO title={props.title ? props.title : "Kajetan Nowak"}/>
    <Logo />
    <div className="main-container">
    <div className="content-container">{props.children}</div>
    <Menu />
    </div>
    <Footer />
    </>
    );
}