import React from 'react';
import { Link } from 'gatsby';

export default function Logo(){
    return(
    <header className="logo">
        <Link to="/">
        <h1><span className="thin">Kajetan</span> <span className="bold">Nowak</span></h1>
        </Link>
    </header>);
}