import React, { useEffect, useState } from 'react';
import NavElement from './NavElement';
import Icon from './Icon';


export default function Menu(props){
    const [open, setOpen] = useState(false);
    const [clicked, setClicked] = useState(false);
    
    
    useEffect(()=>{
        
    },[]);

    const links = [
        {
            url: "photos",
            name: "zdjęcia" 
        },
        {
            url: "videos",
            name: "wideo" 
        },
        {
            url: "web",
            name: "strony" 
        },
        {
            url: "about",
            name: "o mnie" 
        }/*,
        {
            url: "contact",
            name: "kontakt" 
        }*/
    ];

    return(
        <nav className={"menu "+ (clicked ? (open ? "menu-opened" : "menu-closed") : "")} open={open} >
            <button className="btn-icon" open={open} onClick={() => {setOpen(!open); setClicked(true)}}>
                <img 
                src="/icons/hamburger.svg"
                alt={props.alt} 
                className={"icon icon-lr hide-desktop"} 
                 
            />
            </button>
                <ul>
                {links.map((link,i)=><NavElement key={i} url={link.url} onClickEvent={() => {setOpen(false); setClicked(false);}}>{link.name}</NavElement>)}
                </ul>
                <div className="contact-icons">
                <a href="https://instagram.com/kajetan_now"><Icon name="instagram" alt="instagram" type="icon-xl"/></a>
                <a href="https://www.facebook.com/kajetan.nowak.737"><Icon name="facebook" alt="facebook" type="icon-xl"/></a>
                <a href="https://github.com/kajetannow"><Icon name="github" alt="github" type="icon-xl"/></a>
                </div>
                
        </nav>
    );
}