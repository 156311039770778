import React from 'react';


export default function Icon(props){
    return(
        <img src={"/icons/" + props.name + ".svg"} 
        alt={props.alt} 
        className={"icon "+(props.type ? props.type : "icon-md")}
        />
    );
}